
  html {
    scroll-behavior: auto;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
  }
  body {
    background-color: #FCFAF7;
  }

  .pink-text{
    font-size: 1em;
    color:$secondary-2;
    font-family: $font-family-4;
  }
  .hidden{
    display: none;
  }
  .error-message, .label-error{
    font-size: 1em;
    color:$secondary-2;
    font-family: $font-family-4;
 }
  .enrichH-blue-text{
    color:$primary-2;
  }
  a{
    color:$secondary-2;
  }
  .MerriWeather {
    font-family: "Merriweather", serif;
  }

  .container-text{
    width: 90%;
  }
  .errorSelect {
    border: 1px $secondary-2 solid;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  h3 {
    color:$primary-2;
    font-size: 36px;
    margin-top: 50px;
    font-family: "Libre Franklin", sans-serif;
    font-weight: bolder;
    padding-left: 10.5%;
  }

  .accordion .card {
    background-color: #FFFFFF !important;
    width: 100%;
    border: none;
    font-size: 16px;
    padding: 0;
    margin-top:1%;
    margin-bottom: 0;
    box-shadow: $card-shadow !important;
  }
  
  .checkbox-same-address #checkbox-label-address{
    margin: 2% 5%;
    position: relative;
    top: 3px;
  }
  .margin-left{
    padding-left:15px;
  }
  .hide{
    display:none !important;
    transition: ease-in;
  }
  .accordion .card .collapse{
    margin-top:0;
    margin-bottom: 0;
  }
  .accordion .card .collapse.show{
    padding: 0 1rem 1.6rem 1rem;
  }

  .accordion .card .card-header {
    background-color: $primary-2;
    font-family: $font-family-3;
    font-weight: 700;
    color: #FFFFFF;
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 10px !important;
  }

  
  .arrow-img {
    margin-right: 5px;
    filter: invert(100%);
    transition: transform .4s ease-in-out;
    -webkit-filter: invert(100%);
    &.active {
         transform: rotate(180deg);
    }
  }

  .create-user-error {
    margin: auto !important;
  }

  @media (max-width: $breakpoint-sm) {
    #payment-info-footer {
      display: flex;
      flex-direction: column;
    }
    .create-user-error {
      margin-bottom: 1rem !important;
    }
  }

  .stripe-inputs{
    border: 1px solid black !important;
    margin-bottom: 10px;
    padding-left: 5px;
  }

  .stripe-inputs.half-size{
    width: 48%;
    display: inline-block;
  }
  .stripe-inputs.medium-gap{
    margin-right: 4%;
  }
  .active{
    background-color: #FFFFFF;
  }
  .cards-imag{
        margin-left: 50px;
  }
  .wallet-imag{
    width: 30%;
    margin-left: 0px;
  }

  .small-dashed-hr {
    border: 1px dashed;
    color: $border-1;
  }

  .big-dashed-hr {
    border: 3px dashed;
    color: $border-1;
    width: 100%;
  }

  #secure {
    font-family: $font-family-4;
    color: $primary-2;
  }
  
  .pay-later-message{
    margin: 1em;
  }

  @media (max-width: 1400px) {
    .accordion .card {
      background-color: #fafafa;
      width: 100%;
      border: none;
      box-shadow: none;
      font-size: 16px;
    }

    .state-input {
      width: 160%;
      margin-left: -20px;
    }
    .phone_number_input {
      width: 113.5%;
      margin-left: -13%;
    }
    .last-name-input{
      width: 110.5%;
      margin-left: -21px;
    }

  }
  @media (max-width: 1200px) {
    .accordion .card {
      background-color: #fafafa;
      width: 100%;
      border: none;
      box-shadow: none;
      font-size: 15px;
    }

    .wallet-imag{
      width: 50%;
      margin-left: 0px;
    }
  }



#cover-spin {
  position:fixed;
  width:100%;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background-color: rgba(255,255,255,0.7);
  z-index:9999;
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;
    top:40%;
    width:100px;
    height:100px;
    border-style:solid;
    border-color:$secondary-2;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}







