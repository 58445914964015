.card{
    margin: 0 auto;
    border-radius: 0.5em;
    -webkit-box-shadow: 0px 0px 19px -5px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 0px 19px -5px rgba(0,0,0,0.55);
    box-shadow: 0px 0px 19px -5px rgba(0,0,0,0.55);
    padding: 2%;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 100%;
    background-color: #FFFFFF;
}
.pinktitle{
    margin-bottom: 1%;
    font-size: 28px;
    color:#FC3381;
    font-family: 'Merriweather', serif;
}

.new-line{
    display: block;
}

.smallimage img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 15%;
    margin-bottom: 13%;
}

.mediumimage img {
    margin-top: 13%;
    width: 110px;
    height: 110px;
} 

.smallimage, .mediumimage {
    text-align: center;
} 
    
.Element{
    font-size: 20px;
    font-family: 'Libre Franklin', sans-serif;;
}
.min-height{
    min-height: 30px;
}
.blue-text{
    font-size: 26px;
    color: #344C73;
    font-family: 'Merriweather', serif;
}
@media(max-width:1565px){
    .Element{
        font-size: 18px;
        font-family: 'Libre Franklin', sans-serif;;
    }
    .min-height{
        min-height: 80px;
    }
    .mediumimage img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1%;
        width: 80px;
        height: 80px;
    }
    .smallimage img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 20%;
        margin-bottom: 10%;
    }
    .card{
        margin: 0 auto;
        border-radius: 0.5em;
        -webkit-box-shadow: 0px 0px 19px -5px rgba(0,0,0,0.55);
        -moz-box-shadow: 0px 0px 19px -5px rgba(0,0,0,0.55);
        box-shadow: 0px 0px 19px -5px rgba(0,0,0,0.55);
        padding: 2%;
        margin-top: 2%;
        margin-bottom: 2%;
        width: 100%;
        background-color: #FFFFFF;
    }
}
@media(min-width:765px) and (max-width:1100px){
    .Element{
        font-size: 18px;
        font-family: 'Libre Franklin', sans-serif;;
    }
    .min-height{
        min-height: 109px;
    }
    .mediumimage img{
        display: block;
        margin: 10% auto;
        width: 70px;
        height: 70px; 
    }
    .smallimage img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50px;
        height: 50px;
        margin-bottom: 20%;
    }
}
@media(max-width:767px){
    .min-height{
        min-height: 50px;
    }
    .mediumimage img{
        margin: 5% auto;
        width: 110px;
        height: 110px;
    }
    .smallimage img{
        display: block;
        margin: 2% auto;
        width: 20%;
    }
    .pinktitle{
        margin-bottom: 3%;
        font-size: 24px;
        color:#FC3381;
        font-family: 'Merriweather', serif;
    }
    .Element{
        font-size: 16px;
        text-align: center;
        font-family: 'Libre Franklin', sans-serif;;
    } 
}
@media(max-width:600px){
    .card{
        margin: 0 auto;
        border-radius: 0.5em;
        -webkit-box-shadow: 0px 0px 19px -5px rgba(0,0,0,0.55);
        -moz-box-shadow: 0px 0px 19px -5px rgba(0,0,0,0.55);
        box-shadow: 0px 0px 19px -5px rgba(0,0,0,0.55);
        padding: 2%;
        margin-top: 2%;
        margin-bottom: 2%;
        width: 100%;
    }
    .min-height{
        min-height: 0;
    }
    .mediumimage img{
        margin: 0;
        width: 44px;
        height: 44px;
        position: relative;
        bottom: 40px;
    }
     .mediumimage.normal span{
        font-size: 15px;
        text-align: center;
        margin-bottom: 15px;
    }
    .mediumimage.normal {
        font-size: 15px;
        text-align: center;
        margin-bottom: 15px;
    }

    .smallimage img{
        display: block;
        margin: 2% auto;
        width: 13%;
    }
    .pinktitle {
        margin-bottom: 3%;
        font-size: 18px;
        color: #FC3381;
        font-family: Merriweather,serif;
        padding: 6px;
      }
    .mediumimage.small-text-left{
        font-size: 14px;
        text-align: left;
        font-family: 'Libre Franklin', sans-serif;
        margin-bottom: -20px;
    }

    .mediumimage.small-text-left span{
        margin-left: 55px;
    }
    
}

@media(max-width:360px){
    .card{
        margin: 0 auto;
        border-radius: 0.5em;
        -webkit-box-shadow: 0px 0px 19px -5px rgba(0,0,0,0.55);
        -moz-box-shadow: 0px 0px 19px -5px rgba(0,0,0,0.55);
        box-shadow: 0px 0px 19px -5px rgba(0,0,0,0.55);
        padding: 1%;
        margin-top: 2%;
        margin-bottom: 2%;
        width: 100%;
    }
    .min-height{
        min-height: 0;
    }
    .mediumimage img{
        margin: 0;
        width: 30px;
        height: 30px;
        position: relative;
        bottom: 33px;
    }
    .smallimage img{
        display: block;
        margin: 2% auto;
        width: 10%;
    }
    .smallimage{
        font-size: 12px;
    }
    .pinktitle {
        margin-bottom: 1%;
        font-size: 14px;
        color: #FC3381;
        font-family: Merriweather,serif;
        padding: 6px;
      }

      .mediumimage.normal {
        font-size: 16px;
        text-align: center;
        margin-bottom: 15px;
    }
    .mediumimage.normal span{
        font-size: 15px;
        text-align: center;
        margin-bottom: 15px;
    }

    .mediumimage.small-text-left{
        margin-bottom: -25px;
        font-size: 12px;
        text-align: left;
        font-family: 'Libre Franklin', sans-serif;
    }

    .mediumimage.small-text-left span{
        display: inline-block;
        width: 100%;
        margin-top: -3px;
    }
    
}