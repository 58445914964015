.navbar {
  width: 100%;
  background-color: $white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 5px rgba(0, 0, 0, 0.1);

  .navbar-inner {
    max-width: $width-container;
    width: 100%;
    margin: 0 auto;
    padding: 0;

    .toggle {
      width: 2.5rem;
      padding: .5rem;
      cursor: pointer;

      div {
        width: 100%;
        height: 2px;
        margin: .3rem 0;
        background-color: $primary-2;
      }

      &:hover {
        opacity: .8;
      }

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    .link-logo {
      @media screen and (max-width: 768px) {
        flex: 1;
        display: flex;
        justify-content: center;
      }

    }

    .logo {
      max-width: 100%;
      width: 9.375rem;
      height: 100%;
    }

    .menu {

      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: flex-end;
      }

      ul {
        padding: 0;
        margin: 0;
        display: flex;
        list-style: none;

        li {
          padding: .5rem .8rem;

          a {
            font-family: $font-family-4;
            color: $primary-2;
            text-transform: none;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}
