.tooltip-inner {
    color: #f5f5f5 !important;
    background-color: $primary !important;
    font-family: $font-family-2 !important;
    padding: 1rem;
    max-width: 100% !important;
    @media screen and (max-width: 600px) {
        width: 90%;
    }
}
.question-mark-icon {
    cursor: pointer;
}

.billing-tooltip {
    p {
        width: 17.1rem;
        text-align: left;
        margin: .2rem;

        @media screen and (max-width: 600px) {
            width: 80%;
        }

        b {
            font-weight: bold !important;
            font-style: italic !important;
            color: $secondary-3 !important;
        }
    }

    img {
        margin-right: .8rem;
    }
}

.payment-tooltip {
    p {
        width: 16.1rem;
        text-align: left;
        margin: .2rem;

        @media screen and (max-width: 600px) {
            width: 80%;
        }

        b {
            font-weight: bold !important;
            font-style: italic !important;
            color: $secondary-3 !important;
        }
    }
}

.promotion-code {
    p {
        width: 20rem;
        text-align: left;
        margin: .2rem;

        @media screen and (max-width: 600px) {
            width: 80%;
        }

        b {
            font-weight: bold !important;
            font-style: italic !important;
            color: $secondary-3 !important;
        }
    }
}

.tooltip.show {
    opacity: 1;
    filter:alpha(opacity=100);
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: $primary !important;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: $primary !important;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: $primary !important;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: $primary !important;
}