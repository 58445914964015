body,html {
  margin: 0;
  padding: 0;
  color: #000000;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: $font-family-3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &,*, *:after , *:before {
    box-sizing: border-box;
  }
  font-size: 1rem;

  @media screen and (max-width: $breakpoint-sm) {
    font-size: .9rem;
  }
  .view-application{
    background-color: $bg-app;
  }
  .container-ERH{
    max-width: $width-container !important;
    width: 100%;
    min-height: 85vh;
    margin: 0 auto;
}
}
.redirect-page{
  background-color: #fafafa;
  position: relative;
  height: 100vh !important;
}
.redirect-page .container-fluid{
  height: 100%;
  margin-bottom: -100px;
  margin-top: -87px;
}

.headerWrapper {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.headerWrapper h2 {
  margin-top: 20px;
  margin: 0 auto;
  font-size: 30px;
}
.checkout h4{
  padding-top:10% ;
  color:#344C73;
  text-align: center;
}
.headerWrapper h3 {
  margin-top: 20px;
  margin: 0 auto;
  font-size: 26px;
}
.checkout h5{
  margin-top: 5%;
  color:#FC3381;
}

.eligibilityText {
  text-align: left;
  font-size: 1.3em;
  width: 70%;
  height: auto;
  margin: 0 auto 1rem auto
}

.scheduleText {
  margin: 0 auto;
  font-size: 20px;
  width: 70%;
  height: auto;
}


.scheduleWrapper {
  min-width: 90%;
  height: 630px;
}
.scheduleWrapper iframe {
  width: 90%;
}


.masterclassWrapper {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.masterclassWrapper img{
  width: 80%;
  margin: 10% 10%;
}

.masterclassWrapper .row {
  border-bottom: 3px solid gray;
  padding: 20px;
  text-align: center;
  margin: 0 auto;
  align-items: center;
}


.lenderCategories .row {
  border-bottom: 1px solid #3F3F3F;
  padding: 20px;
  text-align: center;
}

.lenderUserName {
  text-align: center;
  padding: 20px;
  color:#344C73;}

.headerWrapper h4{
  text-align: center;
  font-size: 26px;
  width: 70%;
  color:#FC3381;
  margin: 0 auto;
  font-weight: 600;
}


.lenderCategories img {
  width: 100%;
}

.infoText {
  font-weight: 600;
}

.loadingText {
  color: #FC3381;
  font-weight: bold;
  font-size: 2.5em;
  text-align: center;
  padding: 20px;
}

.dataText {
  font-size: 2em;
}


.scheduleWrapper {
  display: flex;
  justify-content: center;
}

.pressSection {
  margin: 10px auto;
  width: 100%;
  background: #FFFFFF;
  margin-top: -8px;
  padding: 50px 10px;
}

.pressSection h4 {
  width: 100%;
  color: #344C73;
  text-align: center;
  padding: 30px;
}

.editorial {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.pressSection img {
  width: 100%;
}
.btn-join{
  margin-top: 18%;
  margin-bottom: -10px;
}
.jumbotron {
  background: #3F3F3F;
  color: #FFFFFF;
  padding: 50px 150px;
  text-align: center;
  margin-bottom: -55px;
}

.jumbotron h2 {
  font-size:26px;
  font-weight: 600;
}

.jumbotron h2,
.jumbotron h5 {
  text-align: center;
  padding: 20px;
}
.jumbotron h5,
.jumbotron p {
  text-align: center;
  font-size: 18px;
}


.jumbotron .aboutMinorText {
  color: #344C73;
}

.highlightedText {
  color: #FC3381;
}

.jumbotron a {
  color: #FC3381;
}
@media (max-width: 1000px) {
  .lenderCategories img {
    width: 40%;
  }
}

@media(max-width:600px){
.jumbotron {
  background: #3F3F3F;
  color: #FFFFFF;
  padding: 0 10px;
  text-align: center;
  margin-bottom: -55px;
}
.jumbotron h2 {
  font-size: 20px;
}
.jumbotron p{
  font-size: 14px;
}
.pressSection {
  margin: 10px auto;
  width: 100%;
  background: #FFFFFF;
  margin-top: -8px;
  padding: 50px 10px;
}

.pressSection h4 {
  width: 100%;
  color: #344C73;
  text-align: center;
  padding: 30px;
}

.pressSection img {
  width: 100%;
}

.headerWrapper {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.headerWrapper h2 {
  margin-top: 20px;
  margin: 0 auto;
  font-size: 20px;

}
.headerWrapper h3 {
  margin-top: 20px;
  margin: 0 auto;
  font-size: 20px;

}


.scheduleText {
  margin: 0 auto;
  font-size: 16px;
  width: 70%;
}
.headerWrapper h4{
  text-align: center;
  font-size: 20px;
  width: 70%;
  color:#FC3381;
  margin: 0 auto;
  font-weight: 600;
}
.lenderCategories img{
  width: 60%;
}
.infoText {
  font-weight: 600;
  font-size: 17px;
}
.dataText{
  font-size: 16px;
}
.button-pink{
  font-size: 18px !important;
  width: 50%;
}
.masterclassWrapper {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

}



@media screen and (max-width: 488px) {
  .lenderUserName {
    width: 95%;
  }
  .pressSection div {
    display: flex;
    justify-content: center;
  }

  .button-pink{
    font-size: 14px !important;
  }
}