.flex {
    display: flex;
}

.blue {
    color: $primary-2;
}

#upselling-products-title {
    margin-bottom: .5rem !important;
}

.upselling-product {
    margin-bottom: 1rem;
}

.upselling-product-body {
    font-family: $font-family-4;
    font-size: 15px;
    font-weight: 500;
    line-height: 28px;
    display: flex;
    flex-direction: column;
    color: $carbon-black;
    p {
        margin: 0 1px;
        color:$black;
    }
}

.upselling-bonus {
    margin-bottom: .3rem;
}

.bonus-box {
    margin-top: 2rem !important;
}

#bonus-arrow{
    margin: 3px 5px 0 0;
    height: 20px;
    width: 20px;
}

.collapsibleBonus {
    cursor: pointer;
    
}

.bonusDescription {
    padding: 0 18px;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;
}

.collapsed {
    max-height: 0;
}

.rotate-down {
    transform: rotate(90deg) translateY(-100%);
    transform-origin:top left;
}

.upselling-checkbox {
    &:hover {
        cursor: pointer;
    }
}