.payment-summary {
    width: 100%;
    padding: 4%;
    box-shadow: $card-shadow;
    border:none;
    .card-title {
        font-family: $font-family-3;
        color: $primary;
        font-size: 20px;
        line-height: 1.4em;
        font-weight: 400;
        padding: 0;
        text-align: center;
        margin-bottom: 0 !important;
    }
    .product-summary {
        color: $primary-2;
        font-size: 1em;
        font-family: $font-family-4;
    }
    .tip-container {
        h6 {
            padding: 0;
            color: $primary-2;
            font-size: 1em;
            font-family: $font-family-4;
            font-weight: 400;
        }
    }
    .complete-purchase {
        .text-total {
            color: $primary-2;
            font-size: 1em;
            font-family: $font-family-4;
            font-weight: 400;
        }
    }
    .purchase-total {
        margin-top: .5rem !important;
        margin-bottom: -.1rem;
    }
}

#payment-summary-title{
    margin-top: -.6rem;
    margin-bottom: .12rem !important;
}

.payment-summary .blue-text{
    font-size: 24px;
}
.space-inbetween-inputs{
    margin: 9px 0;
}


.loader{
    position: relative;
    top: 30px;
    left: 260px;
}

#promotion-code-label {
    padding-top: .5rem;
}

@media (min-width: 1600px) {

    .loader{
        position: relative;
        top: 30px;
        left: 80%;
    }

}
@media(max-width:1200px){
    .payment-summary {
        font-size:14px;
    }
    .payment-summary .blue-text{
        font-size: 20px;
    }
    .loader{
        position: relative;
         top: 30px;
         left: 230px;
    }

}
@media(max-width:1100px){

    .payment-summary {
        font-size:14px;
    }
    .payment-summary .blue-text{
        font-size: 20px;
    }
    .loader{
        position: relative;
        top: 30px;
        left: 80%;
    }
}

@media(max-width:500px){

    .blue-text{
        font-size: 22px;
    }
    .loader{
        position: relative;
         top: 30px;
         left: 80%;
    }
}

@media(max-width:350px){
    .payment-summary {
        font-size:12px;
    }
}