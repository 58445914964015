.btn-plaid {
    outline: none;
    border: solid 1px black;
    color: white;
    background-color: transparent;
    transition: opacity 0.3s ease-in-out;
    width: 100%;
}

.btn-plaid:focus,
.btn-plaid:active,
.btn-plaid:hover {
    box-shadow: none !important;
    outline: none !important;
    color: inherit !important;
    background-color: transparent !important;
    border-color: inherit !important;
}