

.label-input {
    font-family: $font-family-4;
    color: $primary-2;
    font-size: 1em;
}

.ERH-input {
    font-family: $font-family-4;
    width: 100%;
    color: $black;
    font-size: 0.875em;
    border: solid 1px transparent;
    padding: 0.75rem 1.1rem;
    border-radius: 2px;

    &:hover, &:focus {
        border-color: $border-1;
    }

    &:disabled {
        opacity: .6;
    }

    &::placeholder {
        color: $primary-2;
        opacity: .3;
    }
  &.border {
    border-color: #000 !important;
  }
    &.error {
        border-color: $secondary-2 !important;
    }

}
.error-input {
    font-family: $font-family-4;
    font-weight: 500;
    color: $secondary-2;
    margin: .1rem 0 ;
}


input {
  &:active,&:focus,&:hover,& {
    outline: none !important;
  }
}


.container-radio-btn {
  padding: 0.92rem 1.3rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;


}

/*@media screen and (max-width: $breakpoint-sm) {
  .col-radio-btn{
    flex-basis: 100%;
  }
  .container-radio-btn {
    justify-content: flex-start;
  }
}*/
.ERH-radio-btn {

  --active: #{$secondary-2};
  --active-inner: #fff;
  --focus: 2px rgba(#{$secondary-2}, .3);
  --border: #BBC1E1;
  --border-hover: #{$secondary-2};
  --background: #fff;
  --disabled: #F6F8FF;
  --disabled-inner: #E1E6F9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  max-width: 100%;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background .3s, border-color .3s, box-shadow .2s;

  & ~ .borders {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border: solid 1px $border-1;
    @media screen and (max-width: $breakpoint-sm) {
      display: none;
    }
  }

  &:checked ~ .borders {
    border: solid 1px $secondary-2;
  }

  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
  }

  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }

  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: .9;

    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }

    & + label {
      cursor: not-allowed;
    }
  }

  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }

  &:not(.switch) {
    width: 21px;

    &:after {
      opacity: var(--o, 0);
    }

    &:checked {
      --o: 1;
    }
  }

  border-radius: 50%;

  &:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, .7));
  }

  &:checked {
    --s: .5;
  }
}

.label-radio-btn {
  font-family: $font-family-4;
  color: #14222F;
  font-size: 0.9em;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin: 0 .4rem;
  width: 88%;
}


