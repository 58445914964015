
.ERH-btn {
  font-size: 1em !important;
  padding: 0.75rem 1rem !important;
  text-align: center !important;
  max-width: 100% !important;
  border: solid 1px $primary !important;
  font-family: $font-family-3 !important;
  line-height: 1.5em !important;
  cursor: pointer !important;
  transition: all .3s ease-in-out !important;

  &:hover {
    opacity: .8;
  }
  &:active,&:focus {
    opacity: 1;
    outline: none;
  }
  &.round {
    border-radius: 500px !important;
  }
   &:disabled {
     opacity: .6;
   }
  &.outline {
    color: $primary !important;
    background-color: transparent !important;
  }

  &:not(.outline) {
    background-color: $primary !important;
    color: $white !important;
  }
  @media screen and (max-width: $breakpoint-sm) {
    width: 100% !important;
  }
}
